@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .containerr {
        @apply mx-auto px-4 md:px-8 lg:px-12 max-w-[1440px];
    }

    h1{
        @apply text-white text-center lg:text-start text-4xl md:text-6xl font-extrabold   mx-auto md:mx-0
    }

    p {
        @apply text-sm;
    }

    /* testimonials */

    .mySwiper .swiper-pagination-bullet {
        @apply bg-gray-300;
    }

    .mySwiper .swiper-pagination-bullet-active {
        @apply bg-pink;
    }
}

@layer components {
    .btn {
        @apply bg-blue hover:bg-purple text-white px-6 py-3 rounded-lg;
    }

    .circle-blur {
        @apply rounded-full blur-3xl bg-pink/20 absolute
    }

    .input{
        @apply p-3 bg-transparent rounded-lg border-2 border-purple
    }
}


/* animations */
@keyframes animate-y {

    0%,
    100% {
        transform: translateY(-50%);
    }

    50% {
        transform: none;
    }
}

.animate-y {
    animation: animate-y 10s infinite;
}

@keyframes animate-y-icons {

    0%,
    100% {
        transform: translateY(10px);
    }

    50% {
        transform: none;
    }
}

.animate-y-icons {
    animation: animate-y 10s infinite;
}



/* Code Pen Kootoopas */
body {
    background: url("./assets/png/shape.png") repeat 0 0;
    -webkit-animation: bg-scrolling-reverse .92s infinite linear;
    -moz-animation: bg-scrolling-reverse .92s infinite linear;
    -o-animation: bg-scrolling-reverse .92s infinite linear;
    animation: bg-scrolling-reverse .92s infinite linear;
    
}


@-webkit-keyframes bg-scrolling-reverse {
    100% {
        background-position: -50px -50px;
    }
}

@-moz-keyframes bg-scrolling-reverse {
    100% {
        background-position: -50px -50px;
    }
}

@-o-keyframes bg-scrolling-reverse {
    100% {
        background-position: -50px -50px;
    }
}

@keyframes bg-scrolling-reverse {
    100% {
        background-position: -50px -50px;
    }
}